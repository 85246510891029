import React, { useState } from "react";
import { Button, Grid } from "@mui/material";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  Adminwrapper,
  ResizableBoxes,
  TypographyWithMore,
} from "../../../common";
import {
  ADMIN_LINK,
  checkQuilValidation,
  DATA_TYPE,
  isAdminAdd,
  isAdminEdit,
  renderPlaceholder,
  getName,
  isValidUrl,
  LazzyLoading,
} from "../../../common/contentType/contentTypeData";
import { useStyles } from "../../../../utils/styles";
import StickbotTop from "../../../common/contentType/StickbotTop";

const RenderMatterportStickbot = ({ content, user }) => {
  const classes = useStyles();
  const { QUILL, LINK } = DATA_TYPE;
  const myObj = JSON.parse(content?.allContent?.content);
  const { wireframe_link, wireframe_link1 } = myObj;
  const { name, description, type } = content?.allContent;
  const [isIframeReady, setIsIframeReady] = useState(false);
  const [data, setStateData] = useState({
    name,
    description,
    type,
    wireframe_link,
    wireframe_link1,
  });

  const setData = (obj) => {
    setStateData(obj);
    contentFormik.setValues(obj);
  };
  const contentFormik = useFormik({
    initialValues: {
      name: data.name,
      type: data.type,
      description: data.description,
      wireframe_link: data.wireframe_link,
      wireframe_link1: data.wireframe_link1,
    },
    validationSchema: Yup.object({
      name: Yup.string()
        .required("Name is Required")
        .test("matches", "Name is required", checkQuilValidation),
      description: Yup.string()
        .required("Description is Required")
        .test("matches", "Description is required", checkQuilValidation),
      wireframe_link: Yup.string().required("Link is Required"),
      wireframe_link1: Yup.string().required("Link is Required"),
    }),
    onSubmit: () => {
      handleAdminUpdate();
    },
  });
  const handleAdminUpdate = () => {
    window.parent.postMessage(
      isAdminAdd() ? { ...data, points: content?.points } : data,
      ADMIN_LINK
    );
  };
  return (
    <Grid container>
      <Grid item xs={12}>
        <Adminwrapper
          errorText={contentFormik.errors.name}
          dataType={QUILL}
          data={data}
          setData={setData}
          type="name"
        >
          {renderPlaceholder(data?.name, "Enter Name")}
          {/* <QuillEditor sx={quilStyle} value={data?.name} readOnly={true} /> */}
          <TypographyWithMore
            textAlign="center"
            textLength={8}
            lines={1}
            variant="h2"
            color="primary"
            data={data?.name}
          />
        </Adminwrapper>
      </Grid>
      {!!data?.description?.replace(/<[^>]*>/g, "").trim() && (
        <Grid
          item
          xs={data?.description?.replace(/<[^>]*>/g, "").trim() ? 12 : 0}
        >
          <Adminwrapper
            errorText={contentFormik.errors.description}
            dataType={QUILL}
            data={data}
            setData={setData}
            type="description"
          >
            {renderPlaceholder(data?.description, "Enter Description")}
            {/* <QuillEditor
                            sx={quilStyle}
                            value={data?.description}
                            readOnly={true}
                        /> */}

            <TypographyWithMore
              textAlign="center"
              textLength={50}
              lines={4}
              variant="body1"
              color="text"
              data={data?.description}
            />
          </Adminwrapper>
        </Grid>
      )}
      <ResizableBoxes height={{ sm: "60vh", xs: "85vh" }}>
        <>
          <Adminwrapper
            errorText={contentFormik.errors.wireframe_link}
            dataType={LINK}
            data={data}
            setData={setData}
            type="link1"
            label={
              data?.type === "Money-Stickbot" ? "Money Link" : "Matterport Link"
            }
          >
            {/* <StickbotTop url={ isValidUrl(data?.wireframe_link)
                  ? data?.wireframe_link +
                    "?token=" +
                    localStorage.getItem("token") +
                    "&isIframe=true"
                  : "www.google.com"} /> */}
            {!isIframeReady && <LazzyLoading />}
            <iframe
              onLoad={() => setIsIframeReady(true)}
              style={{
                opacity: isIframeReady ? 1 : 0,
                width: "100%",
              }}
              // className="i-matport"
              allow="camera; microphone"
              width="100%"
              height="100%"
              src={
                isValidUrl(data?.wireframe_link)
                  ? data?.wireframe_link +
                  "?token=" +
                  localStorage.getItem("token") +
                  "&isIframe=true"
                  : "www.google.com"
              }
              frameBorder="0"
            ></iframe>
          </Adminwrapper>
        </>

        <>
          <Adminwrapper
            errorText={contentFormik.errors.wireframe_link1}
            dataType={LINK}
            data={data}
            setData={setData}
            type="link2"
            label={"Stickbot Link"}
          >


            <StickbotTop url={isValidUrl(data?.wireframe_link1)
              ? data?.wireframe_link1 + `?userId=${getName(user)}`
              : "www.google.com"} />
            {/* {!isIframeReady && <LazzyLoading />}
            <iframe
              onLoad={() => setIsIframeReady(true)}
              style={{
                width: "100%",
                height: "100%",
                opacity: isIframeReady ? 1 : 0,
              }}
              src={
                isValidUrl(data?.wireframe_link1)
                  ? data?.wireframe_link1 + `?userId=${getName(user)}`
                  : "www.google.com"
              }
              frameBorder="0"
            ></iframe> */}
          </Adminwrapper>
        </>
      </ResizableBoxes>
      {isAdminEdit() && (
        <Button
          className={classes.btnUpdateStyle}
          onClick={contentFormik.handleSubmit}
        >
          Update
        </Button>
      )}
      {isAdminAdd() && (
        <Button
          className={classes.btnAddStyle}
          onClick={contentFormik.handleSubmit}
        >
          ADD
        </Button>
      )}
    </Grid>
  );
};

export default RenderMatterportStickbot;
