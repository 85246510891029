import React, { useRef, useEffect } from 'react';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
import { EffectCube, Pagination } from 'swiper/modules';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/effect-cards';

import './style.css';

// import required modules
import { EffectCards } from 'swiper/modules';
import SkillSection from '../../SkillSection';

export default function MobileCards({ data, handleNextClick, handlePrevClick }) {
    const swiperRef = useRef(null);

    const pauseAllMediaExceptCurrent = (currentIndex) => {
        const slides = swiperRef.current.swiper.slides;
        slides.forEach((slide, index) => {
            if (index !== currentIndex) {
                const mediaElements = slide.querySelectorAll('video, audio');
                mediaElements.forEach(media => {
                    console.log("Media Uability: ",media);
                    media.pause();
                    media.autoplay = false;
                    media.currentTime = 0; // Reset to start
                    media.muted = true; // Mute the media
                    // if (media.tagName === 'VIDEO' || media.tagName === 'AUDIO') {
                    //     media.src = ''; // Stop the media by resetting the source
                    // }
                });
            }
        });
    };

    const handleSlideChange = (swiper) => {
        const currentIndex = swiper.activeIndex;
        const previousIndex = swiper.previousIndex;

        pauseAllMediaExceptCurrent(currentIndex);

        if (currentIndex > previousIndex) {
            if (previousIndex === data.length - 1) {
                handleNextClick();
                return;
            }
            handleNextClick();
        } else if (currentIndex < previousIndex) {
            if (previousIndex === 0) {
                handlePrevClick();
                return;
            }
            handlePrevClick();
        }
    };

    useEffect(() => {
        if (swiperRef.current) {
            swiperRef.current.swiper.update(); // Refresh the swiper when data changes
            pauseAllMediaExceptCurrent(swiperRef.current.swiper.activeIndex); // Ensure media is paused on initial render
        }
    }, [data]);

    return (
        <>
            <Swiper
                ref={swiperRef}
                effect={'cards'}
                grabCursor={true}
                modules={[EffectCards]}
                className="mySwiper"
                onSlideChange={handleSlideChange}
            >
                {
                    data?.map(content => {
                        return (
                            <SwiperSlide key={content.id}>
                                <SkillSection content={content} />
                            </SwiperSlide>
                        )
                    })
                }
                <SwiperSlide></SwiperSlide>
            </Swiper>
        </>
    );
}
