import React, { useState, useEffect } from "react";
import {
  Card,
  Grid,
  IconButton,
  LinearProgress,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { Typography, Box } from "@mui/material";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import Button from "@mui/material/Button";
import RadioGroup from "@mui/material/RadioGroup";
import toast from "react-hot-toast";
import TextField from "@mui/material/TextField";
import { borderRadius, fontSize } from "@mui/system";
import DoneIcon from "@mui/icons-material/Done";
import ClearIcon from "@mui/icons-material/Clear";
import Parser from "html-react-parser";
import { QuillEditor } from "../../details/quil-editor";
import CustomCardWrapper from "../../../common/contentType/CustomCardWrapper";
import {
  extractTextFromHTML,
  LazzyLoading,
} from "../../../common/contentType/contentTypeData";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ArrowForwardIosRoundedIcon from "@mui/icons-material/ArrowForwardIosRounded";
import { useTranslation } from "react-i18next";
import { analyticFormSubmission, getForm, submitResponse } from "../../../_api/google-forms";
import { useDispatch, useSelector } from "react-redux";
import { setNextEnabled } from "../../../../redux-features/user/userSlice";


const titleStyle = {
  fontStyle: "normal",
  color: "#242424",
  fontWeight: 700,
  fontSize: { sm: "16px", xs: "12px" },
};

const descStyle = {
  fontStyle: "normal",
};

const sectionStyle = {
  padding: "12px 24px",
  width: "100%",
  backgroundColor: "#5048E5",
  borderRadius: "15px",
};

const qCardStyle = {
  mt: "5px",
  borderRadius: "15px",
  border: "1px solid #D8D7D7",
  padding: "10px 12px",
  display: "flex",
  flexDirection: { sm: "row", xs: "column" },
};


const inputField = {
  fontFamily: "Poppins",
  // p: "5px 8px",
  mt: 5
};

function GoogleForm({ content }) {
  const dispatch = useDispatch();
  const formId = content?.allContent?.content;
  const { i18n } = useTranslation();
  const lang = i18n.language || "en";
  const user = useSelector((state) => state?.user?.userData?.user);
  const isNextEnabled = useSelector((state) => state.user?.isNextEnabled);
  const userID = user?.id;
  const [formData, setFormData] = useState({});
  const [responseData, setResponseData] = useState([]);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [questions, setQuestions] = useState([]);
  const [questionsIndex, setQuestionsIndex] = useState(0);
  const [value, setValue] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [isShownCorrect, setShowCorrect] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [analyticsData, setAnalyticsData] = useState([])
  const lessonIndex = useSelector(state => state.user.lessonIndex)
  const skillIndex = useSelector(state => state.user.skillIndex)
  const lessonName = useSelector(state => state.user.lessonName)
  const skillName = useSelector(state => state.user.skillName)
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const handleRadioChange = (j, i, free) => {
    var questionId = questions[i].id;
    var optionId = j;
    var freeText = "";

    let tempAnalyticsData = {
      question_statement: questions[i]?.questionText,
      answer: free ? j : questions[i]?.options.find(option => option.id == j)?.optionText || ""
    }

    if (free) {
      freeText = j;
      optionId = "";
    }
    var data = {
      questionId,
      freeText,
      optionId: parseInt(optionId),
    };
    let temp = {
      qId: questionId,
      opt: j,
    };
    if (value?.length == 0) {
      setValue([...value, temp]);
      setAnalyticsData([...analyticsData, tempAnalyticsData])
    } else {
      var indexOfVal = value.findIndex((x) => x.qId == questionId);
      if (indexOfVal == -1) {
        setValue([...value, temp]);
        setAnalyticsData([...analyticsData, tempAnalyticsData])
      } else {
        value[indexOfVal].opt = optionId;
        analyticsData[indexOfVal].answer = free ? j : questions[i]?.options.find(option => option.id == j)?.optionText || "";
        setValue(value);
        setAnalyticsData(analyticsData)
      }
    }
    var fakeRData = [...responseData];
    var indexOfResponse = fakeRData.findIndex(
      (x) => x?.questionId == questionId
    );
    let tempResponse = responseData;
    if (indexOfResponse === -1) {
      tempResponse[i] = data;
      setResponseData(tempResponse);
    } else {
      fakeRData[indexOfResponse].questionId = questionId;
      if (free) {
        fakeRData[indexOfResponse].freeText = freeText;
      }
      setResponseData(fakeRData);
    }
  };

  const resultData = () => {
    if (!formData?.questions?.length && !responseData?.length)
      return { perc: "N/A", correctCount: "N/A" };
    if (formData?.multiValueForm) {
      let totalPoints = 0;
      let obtainedPoints = 0;
      formData.questions.map((question, i) => {
        question?.options?.map((opt) => {
          if (opt?.id == responseData[i].optionId) {
            obtainedPoints += opt?.optionValue;
          }
        });
      });
      return obtainedPoints;
    }
    const correctAnswers = formData.questions.filter(
      (question, i) =>
        responseData[i]?.optionText ||
        question.options[question.trueValue - 1]?.id ==
        responseData[i]?.optionId
    );
    const textCount = formData.questions.filter(
      (val) => val.isFreeText === true
    ).length;
    const questionLength = responseData.length - textCount;
    const percentage =
      correctAnswers && questionLength
        ? ((correctAnswers.length / questionLength) * 100).toFixed(2)
        : 0;

    return {
      perc: percentage,
      correctCount: correctAnswers.length,
      questionLength,
    };
  };

  useEffect(() => {
    window.parent.postMessage(
      { adminForm: { isSubmitted: false } },
      process.env.NEXT_PUBLIC_APP_CLIENT
    );
  }, []);
  useEffect(() => {
    toast.loading(lang === "sp" ? "Cargando..." : "Loading...");
    setIsLoading(true);
    if (formId) {
      getForm(formId).then(
        (rowData) => {
          const data = {
            ...rowData,
            questions: rowData?.questions?.map((question) => ({
              ...question,
              options: question?.options?.reverse() || [],
            })),
          };
          setFormData(data);
          setQuestions(data?.questions || []);
          if (!data?.questions?.length) {
            dispatch(setNextEnabled(false));
          } else {
            dispatch(setNextEnabled(true));
          }
          toast.dismiss();
          setIsLoading(false);
        },
        (error) => {
          const resMessage =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          toast.dismiss();
          setIsLoading(false);
        }
      );
    }
  }, [formId]);
  function handleResponse() {
    toast.loading(
      lang === "sp" ? "Enviando formulario..." : "Submiting form..."
    );
    var submissionData = {
      userId: userID,
      formId: formData.id,
      response: responseData,
    };
    // submitResponse(submissionData).then(
    //   (data2) => {
    //     setIsSubmitted(true);
    //     toast.dismiss();
    //     toast.success(lang === "sp" ? "Enviado" : "Submitted");
    //     dispatch(setNextEnabled(false));
    //   },
    //   (error) => {
    //     const resMessage =
    //       (error.response &&
    //         error.response.data &&
    //         error.response.data.message) ||
    //       error.message ||
    //       error.toString();
    //     toast.dismiss();
    //   }
    // );
    let email = localStorage.getItem("userEmail")
    let userDetials = JSON.parse(localStorage.getItem("userDetails"))
    let payload = {
      user_name: userDetials?.name,
      user_email: email,
      successcoach_name: userDetials?.coach,
      mentor_name: userDetials?.mentor,
      organization_id: user?.organizationId,
      form_name: `${lessonName} | ${skillName}`,
      form_submission: analyticsData,
      lesson_index: lessonIndex,
      skill_index: skillIndex
    }
    setIsSubmitted(true);
    toast.dismiss();
    toast.success(lang === "sp" ? "Enviado" : "Submitted");
    dispatch(setNextEnabled(false));
    if (formData?.name?.toLowerCase()?.includes("completing")) {
      analyticFormSubmission(payload)
    }

  }

  const incrementIndex = (index) => {
    if (index < questions.length - 1) {
      index = index + 1;
    }
    setQuestionsIndex(index);
  };
  const decrementIndex = (index) => {
    if (index > 0) {
      index = index - 1;
    }
    setQuestionsIndex(index);
  };

  const getFileExtensionName = (file) => {
    let fileName = file;
    let regex = new RegExp("[^.]+$");
    let extension = fileName?.match(regex);
    return extension?.[0];
  };

  const renderAllQuestions = () => {
    let preSection = "";
    let sectionBox = (preS, currentS) => {
      if (preS !== currentS) {
        preSection = currentS;
        return (
          <Box sx={{ ...sectionStyle, mt: 1 }}>
            <Typography sx={{ ...titleStyle, color: "#ffff" }}>
              {currentS}
            </Typography>
          </Box>
        );
      }
      return <></>;
    };
    return (
      <div>
        {questions.map((val, ind) => (
          <>
            {val.isFreeText ? (
              <></>
            ) : (
              <>
                {sectionBox(preSection, val.section)}
                <Box>
                  <div key={ind}>
                    <div>
                      <Box sx={qCardStyle}>
                        {true && (
                          <Box
                            sx={{
                              width: { sm: "50%", xs: "100%" },
                              display: "flex",
                              justifyContent: "center",
                              flexDirection: { sm: "row", xs: "column" },
                            }}
                          >
                            {checkFileName == "mp4" ||
                              checkFileName === "m4v" ? (
                              <video className="google-video" controls>
                                <source
                                  src={questions?.[ind]?.questionImage}
                                  type="video/mp4"
                                ></source>
                              </video>
                            ) : (
                              <img
                                style={{ borderRadius: "20px", width: "100%" }}
                                src={questions?.[ind]?.questionImage}
                                width="auto"
                                alt=""
                              />
                            )}
                          </Box>
                        )}
                        {questions[ind]?.isFreeText ? (
                          <Box
                            sx={{ display: "flex", flexDirection: "column" }}
                          >
                            <Typography variant="subtitle1" sx={titleStyle}>
                              {questions[ind]?.questionText}
                            </Typography>
                            <TextField
                              required
                              variant="outlined"
                              label="Write Your Answer"
                              sx={inputField}
                              value={responseData[ind].freeText}
                              id="filled-multiline-static"
                              multiline
                              fullWidth
                              disabled
                              placeholder={
                                lang === "sp"
                                  ? "Escribe tu respuesta"
                                  : "Write Your Answer"
                              }
                              onChange={(e) => { }}
                            />
                          </Box>
                        ) : (
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "center",
                              ml: 3,
                            }}
                          >
                            {/* <Typography variant="subtitle1" sx={titleStyle}>
                              {questions[ind]?.questionText}
                            </Typography> */}
                            <RadioGroup
                              aria-label={ind}
                              id={"question " + (ind + 1)}
                              name={"question " + (ind + 1)}
                              value={value[ind]}
                              onChange={(e) => { }}
                              sx={{
                                ...descStyle,
                                padding: "10px",
                                height: "fit-content",
                              }}
                            >
                              {questions[ind]?.options?.map((op, j) => (
                                <div key={j}>
                                  <Box
                                    style={{
                                      display: "flex",
                                      marginLeft: "5px",
                                      padding: "5px 10px",
                                      marginTop: 2,
                                      alignItems: "center",
                                      borderRadius: "10px",
                                      justifyContent: "space-between",
                                      backgroundColor:
                                        questions[ind]?.trueValue - 1 == j
                                          ? "#e6f4ea"
                                          : responseData[ind]?.optionId ==
                                          op.id && "#fce8e5",
                                    }}
                                  >
                                    <Box>
                                      <FormControlLabel
                                        sx={{
                                          "& span": {
                                            fontSize: {
                                              sm: "14px",
                                              xs: "12px",
                                            },
                                          },
                                        }}
                                        value={op.optionText}
                                        control={<Radio />}
                                        label={op.optionText}
                                        checked={
                                          questions[ind]?.trueValue - 1 == j
                                        }
                                      />
                                    </Box>
                                    <Box sx={{ mr: 2 }}>
                                      {questions[ind]?.trueValue - 1 == j ? (
                                        <DoneIcon sx={{ color: "#3ec35c" }} />
                                      ) : (
                                        responseData[ind]?.optionId ==
                                        op.id && (
                                          <ClearIcon
                                            sx={{ color: "#ed3c3c" }}
                                          />
                                        )
                                      )}
                                    </Box>
                                  </Box>
                                </div>
                              ))}
                            </RadioGroup>
                          </Box>
                        )}
                      </Box>
                    </div>
                  </div>
                </Box>
              </>
            )}
          </>
        ))}
        <Box>
          <br></br>
        </Box>
      </div>
    );
  };

  const renderResult = () =>
    formData?.multiValueForm || formData?.name?.toLowerCase()?.includes("completing") ? (
      <>
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            p: { sm: "0 20px", xs: "5px" },
            alignItems: "center",
            backgroundColor: "#F9FAFC",
          }}
        >
          <StyleResultString resultData={resultData} formData={formData} />
        </Box>
      </>
    ) : (
      <Box
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Box sx={{ textAlign: "center" }}>
          <Typography variant="h2" color={theme.palette.primary.main}>
            {`${resultData().perc}%`}
          </Typography>
          <Typography variant="subTitle1" color="text">
            {lang === "sp" ? "Tu resultado" : "Your Result"}
          </Typography>
        </Box>

        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            bgcolor: theme.palette.primary.main,
            color: theme.palette.common.white,
            padding: "10px 15px",
            borderRadius: "12px",
            gap: "5px",
          }}
        >
          <Typography variant="subTitle1">
            {lang === "sp" ? "Puntos totales" : "Total Points"}:
          </Typography>
          <Typography fontWeight="bold" variant="subTitle1">
            {`${resultData().correctCount}/${resultData().questionLength}`}
          </Typography>
        </Box>
      </Box>
    );

  const checkFileName = getFileExtensionName(
    questions?.[questionsIndex]?.questionImage
  );
  return (
    <Grid
      container
      display="flex"
      flexDirection="column-reverse"
      justifyContent="center"
      alignItems="center"
      sx={{ background: "#fff" }}
    >
      {isLoading ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        >
          <LazzyLoading />
        </Box>
      ) : (
        <Grid item xs={12} sx={{ width: "100%" }}>
          {!isOpen && (
            <CustomCardWrapper height={"70vh"}>
              <Grid
                container
                spacing={2}
                flexDirection={{ xs: "column-reverse", md: "row" }}
                height={{ md: "100%" }}
              >
                <Grid
                  item
                  xs={12}
                  md={7}
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                  }}
                >
                  <Box mx={{ xs: 0, md: 4 }}>
                    <Typography
                      sx={{ whiteSpace: "pre-wrap" }}
                      variant="h2"
                      color="primary"
                    >
                      {extractTextFromHTML(formData?.name)}
                    </Typography>
                    <Typography
                      sx={{ whiteSpace: "pre-wrap" }}
                      variant="body1"
                      color="text"
                    >
                      {extractTextFromHTML(formData?.description)}
                    </Typography>
                    <Box mt={{ xs: "1rem", md: "3rem" }} width="60%">
                      <Button
                        disabled={!isNextEnabled}
                        onClick={() => setIsOpen(true)}
                        variant="contained"
                        fullWidth
                      >
                        {lang === "sp" ? "Empezar" : "Get Started"}
                      </Button>
                    </Box>
                  </Box>
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={5}
                  width="100%"
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                >
                  <Box
                    sx={{
                      width: "100%",
                      maxHeight: "50vh",
                      aspectRatio: "2/3",
                    }}
                  >
                    <img
                      src={formData?.image}
                      alt={""}
                      style={{
                        width: "100%",
                        height: "100%",
                        objectFit: "contain",
                        borderRadius: "1.8rem",
                      }}
                    />
                  </Box>
                </Grid>
              </Grid>
            </CustomCardWrapper>
          )}
          {isOpen && (
            <>
              {!isSubmitted ? (
                <Card
                  sx={{
                    backgroundColor: theme.palette.common.dialougeBg,
                    borderRadius: "1.8rem",
                    border: `1px solid ${theme.palette.common.disabled}`,
                    boxShadow: "none",
                    width: "`100%",
                    height: { md: "75vh", xs: "95vh" },
                    p: "1.5rem",
                    position: "relative",
                  }}
                >
                  <Card
                    sx={{
                      backgroundColor: theme.palette.primary.main,
                      borderRadius: ".8rem",
                      boxShadow: "none",
                      width: "`100%",
                      p: ".5rem",
                    }}
                  >
                    <Typography
                      variant="h5"
                      color={theme.palette.common.white}
                      textAlign="center"
                    >
                      {questions[questionsIndex]?.section}
                    </Typography>
                  </Card>
                  <Card
                    sx={{
                      backgroundColor: theme.palette.common.white,
                      borderRadius: "1.3rem",
                      boxShadow: "none",
                      height: isMobile ? "95vh" : "50vh",
                      overflow: "auto",
                      width: "100%",
                      p: "1rem",
                      mt: "1rem",
                    }}
                  >
                    {questions && (
                      <Box key={questionsIndex}>
                        {questions[questionsIndex]?.isFreeText ? (
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              mb: 3,
                              justifyContent: "center",
                            }}
                          >
                            <Box display="flex" gap={1}>
                              <Typography
                                fontSize={{ xs: "12px", md: "18px" }}
                                color="text"
                              >
                                {questionsIndex + 1}.
                              </Typography>
                              <Typography
                                fontSize={{ xs: "12px", md: "18px" }}
                                fontWeight="700"
                                color="text"
                              >
                                {questions[questionsIndex]?.questionText}
                              </Typography>
                            </Box>

                            <TextField
                              label="Write Your Answer (at least 25 words)"
                              required
                              variant="outlined"
                              sx={inputField}
                              multiline
                              fullWidth
                              rows={8}
                              FormHelperTextProps={{ style: { textAlign: 'right', marginRight: 10 } }}
                              helperText={`${analyticsData[questionsIndex]?.answer?.split(" ")?.length || 0}/25`}
                              placeholder={
                                lang === "sp"
                                  ? "Escribe tu respuesta"
                                  : "Write Your Answer"
                              }
                              onChange={(e) => {
                                handleRadioChange(
                                  e.target.value,
                                  questionsIndex,
                                  true
                                );
                              }}
                            />
                          </Box>
                        ) : (
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: { xs: "column", md: "row" },
                              gap: 1,
                            }}
                          >
                            <Box
                              width={{
                                xs: "100%",
                                md: questions[questionsIndex]?.questionImage
                                  ? "50%"
                                  : "100%",
                              }}
                            >
                              <Box display="flex" gap={1}>
                                <Typography
                                  fontSize={{ xs: "12px", md: "18px" }}
                                  color="text"
                                >
                                  {questionsIndex + 1}.
                                </Typography>
                                <Typography
                                  fontSize={{ xs: "12px", md: "18px" }}
                                  fontWeight="700"
                                  color="text"
                                >
                                  {questions[questionsIndex]?.questionText}
                                </Typography>
                              </Box>

                              <RadioGroup
                                aria-label={questionsIndex}
                                id={"question " + (questionsIndex + 1)}
                                name={"question " + (questionsIndex + 1)}
                                value={value[questionsIndex]?.opt}
                                onChange={(e) => {
                                  handleRadioChange(
                                    e.target.value,
                                    questionsIndex,
                                    false
                                  );
                                }}
                                sx={{ ...descStyle, padding: "6px" }}
                              >
                                <Box>
                                  {questions[questionsIndex]?.options?.map(
                                    (op, j) => (
                                      <Box key={j}>
                                        <FormControlLabel
                                          sx={{
                                            color: "text",
                                            "& span": {
                                              fontSize: {
                                                md: "14px",
                                                xs: "10px",
                                              },
                                              // p: {
                                              //   xs: "2px",
                                              //   md: "6px",
                                              // },
                                            },
                                          }}
                                          value={op.id}
                                          control={
                                            <Radio
                                              // defaultChecked={
                                              //   value[j]?.opt == op.id
                                              // }
                                              sx={{
                                                color:
                                                  theme.palette.primary.light,
                                                "&.Mui-checked": {
                                                  color:
                                                    theme.palette.primary.main,
                                                },
                                                "& .MuiSvgIcon-root": {
                                                  fontSize: {
                                                    xs: "15px",
                                                    md: "26px",
                                                  },
                                                },
                                              }}
                                            />
                                          }
                                          label={op.optionText}
                                        />
                                      </Box>
                                    )
                                  )}
                                </Box>
                              </RadioGroup>
                            </Box>
                            {questions[questionsIndex]?.questionImage && (
                              <Box width={{ xs: "100%", md: "50%" }}>
                                <Box
                                  sx={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                  }}
                                >
                                  {checkFileName == "mp4" ||
                                    checkFileName === "m4v" ? (
                                    <Box
                                      sx={{
                                        width: "100%",
                                        maxHeight: isMobile ? "70vh" : "40vh",
                                        aspectRatio: "1/1",
                                      }}
                                    >
                                      <video
                                        className="google-video"
                                        controls
                                        style={{
                                          width: "90%",
                                          height: "100%",
                                          objectFit: "contain",
                                          borderRadius: "1rem",
                                        }}
                                      >
                                        <source
                                          src={
                                            questions?.[questionsIndex]
                                              ?.questionImage
                                          }
                                          type="video/mp4"
                                        ></source>
                                      </video>
                                    </Box>
                                  ) : (
                                    <Box
                                      sx={{
                                        width: "100%",
                                        maxHeight: isMobile ? "70vh" : "40vh",
                                        aspectRatio: "1/1",
                                      }}
                                    >
                                      <img
                                        style={{
                                          width: "90%",
                                          height: "100%",
                                          objectFit: "contain",
                                          borderRadius: "1rem",
                                        }}
                                        src={
                                          questions?.[questionsIndex]
                                            ?.questionImage
                                        }
                                        alt=""
                                      />
                                    </Box>
                                  )}
                                </Box>
                              </Box>
                            )}
                          </Box>
                        )}
                      </Box>
                    )}
                  </Card>
                  {/* Mobile */}
                  <Box
                    sx={{
                      display: { xs: "block", md: "none" },
                      flexDirection: "column",
                      width: "90%",
                      gap: 0.5,
                      position: "absolute",
                      bottom: 50,
                    }}
                  >
                    <LinearProgress
                      variant="determinate"
                      value={((questionsIndex + 1) * 100) / questions?.length}
                      sx={{
                        height: 5,
                        borderRadius: 5,
                        backgroundColor: theme.palette.common.white,
                        border: `1px solid ${theme.palette.common.disabled}`,
                        "& .MuiLinearProgress-bar": {
                          backgroundColor: theme.palette.success.main,
                        },
                      }}
                    />
                    <Typography color="text" textAlign="center" fontSize="10px">
                      {`${questionsIndex + 1}/${questions?.length}`}
                    </Typography>
                  </Box>

                  <Box
                    display="flex"
                    gap="4rem"
                    justifyContent={{ xs: "space-between", md: "space-around" }}
                    alignItems="center"
                    mt="1.5rem"
                    position="absolute"
                    bottom={20}
                    width={{ xs: "90%", md: "95%" }}
                    px={{ xs: "0rem", md: "5rem" }}
                  >
                    <Button
                      variant="text"
                      disabled={questionsIndex === 0}
                      onClick={() => decrementIndex(questionsIndex)}
                      startIcon={<ChevronLeftIcon sx={{ fontSize: "2rem" }} />}
                      sx={{
                        fontSize: "1rem",
                        fontWeight: "bold",
                        color: theme.palette.primary.main,
                      }}
                    >
                      {lang === "sp" ? "Próximo" : "Previous"}
                    </Button>

                    <Box
                      sx={{
                        display: { xs: "none", md: "flex" },
                        flexDirection: "column",
                        width: "50%",
                        gap: 0.5,
                      }}
                    >
                      <LinearProgress
                        variant="determinate"
                        value={((questionsIndex + 1) * 100) / questions?.length}
                        sx={{
                          height: 7,
                          borderRadius: 5,
                          backgroundColor: theme.palette.common.white,
                          border: `1px solid ${theme.palette.common.disabled}`,
                          "& .MuiLinearProgress-bar": {
                            backgroundColor: theme.palette.success.main,
                          },
                        }}
                      />
                      <Typography
                        color="text"
                        textAlign="center"
                        variant="body1"
                      >
                        {`${questionsIndex + 1}/${questions?.length}`}
                      </Typography>
                    </Box>

                    {questionsIndex === questions?.length - 1 ? (
                      <Button
                        variant="contained"
                        color="primary"
                        disabled={(value.length !== questions.length) || (questions[questionsIndex]?.isFreeText && analyticsData[questionsIndex]?.answer?.split(" ")?.length < 25)}
                        onClick={handleResponse}
                      >
                        {lang === "sp" ? "Entregar" : "Submit"}
                      </Button>
                    ) : (
                      <Button
                        variant="contained"
                        disabled={(value.length == questionsIndex) || (questions[questionsIndex]?.isFreeText && analyticsData[questionsIndex]?.answer?.split(" ")?.length < 25)}
                        onClick={() => incrementIndex(questionsIndex)}
                        sx={{ px: "3rem" }}
                      >
                        {lang === "sp" ? "Próximo" : "Next"}
                      </Button>
                    )}
                  </Box>
                </Card>
              ) : (
                <Box>
                  <Card
                    sx={{
                      backgroundColor: theme.palette.common.lightGray,
                      borderRadius: "1.8rem",
                      border: `1px solid ${theme.palette.common.disabled}`,
                      boxShadow: "none",
                      width: "100%",
                      height: { md: "72vh", xs: "95vh" },
                      p: "1.5rem",
                      overflowY: "auto",
                    }}
                  >
                    <Grid container spacing={3}>
                      <Grid
                        item
                        xs={12}
                        md={5}
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                      >
                        <Box
                          width={{ xs: "90%", sm: "60%", md: "70%", xl: "80%" }}
                        // height="100%"
                        >
                          <img
                            src="/assets/img/goal.png"
                            alt=""
                            style={{ width: "100%", height: "auto" }}
                          />
                        </Box>
                      </Grid>
                      <Grid item xs={12} md={7}>
                        <Card
                          sx={{
                            backgroundColor: theme.palette.common.white,
                            borderRadius: "1.3rem",
                            boxShadow: "none",
                            width: "`100%",
                            height: "100%",
                            maxHeight: isMobile ? "80vh" : "62vh",
                            p: "1rem",
                            overflow: "auto",
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "space-between",
                          }}
                        >
                          {formData?.analyticsEnabled && (
                            <Box display="flex" flexDirection="column" gap={1}>
                              <Typography
                                variant="subTitle1"
                                fontWeight="500"
                                color="primary"
                                sx={{ whiteSpace: "pre-wrap" }}
                              >
                                {extractTextFromHTML(formData?.name)}
                              </Typography>

                              <Typography
                                variant="subTitle1"
                                fontWeight="500"
                                color="text"
                                sx={{ whiteSpace: "pre-wrap" }}
                              >
                                {extractTextFromHTML(formData?.description)}
                              </Typography>
                            </Box>
                          )}

                          {isShownCorrect
                            ? renderAllQuestions()
                            : renderResult()}
                        </Card>
                      </Grid>
                    </Grid>
                    <Box
                      sx={{
                        mt: "1rem",
                        display: "flex",
                        flexDirection: "column",
                        gap: 2,
                        alignItems: "center",
                      }}
                    >
                      {formData?.analyticsEnabled && (
                        <Box>
                          <Button
                            variant="contained"
                            onClick={() => setShowCorrect(!isShownCorrect)}
                          >
                            {isShownCorrect
                              ? lang === "sp"
                                ? "Resultado"
                                : "Result"
                              : lang === "sp"
                                ? "Respuestas correctas"
                                : "Correct Answers"}
                          </Button>
                          <Typography variant="h6" textAlign="center">
                            {lang === "sp" ? "O" : "OR"}
                          </Typography>
                        </Box>
                      )}
                    </Box>
                  </Card>
                </Box>
              )}
            </>
          )}
        </Grid>
      )}
    </Grid>
  );
}

export default GoogleForm;

const StyleResultString = ({ formData, resultData }) => {
  const theme = useTheme();
  return (
    <>
      {formData?.name?.toLowerCase().includes("personality explorer") ? (
        <Box sx={{ my: 5 }}>
          <Typography variant="h5">Your Personality Style:</Typography>
          {resultData() > 60 ? (
            <Typography sx={{ whiteSpace: "pre-wrap" }}>
              <strong>Dominant (D)</strong> <br />- Assertive, results-driven,
              and enjoys leadership roles.
            </Typography>
          ) : resultData() > 45 ? (
            <Typography sx={{ whiteSpace: "pre-wrap" }}>
              <strong>Influence (i)</strong> <br />- Enthusiastic, social, and
              persuasive.
            </Typography>
          ) : resultData() > 30 ? (
            <Typography sx={{ whiteSpace: "pre-wrap" }}>
              <strong>Steadiness (S)</strong> <br />- Cooperative, dependable,
              and appreciates stability.
            </Typography>
          ) : (
            <Typography sx={{ whiteSpace: "pre-wrap" }}>
              <strong>Conscientiousness (C)</strong> <br />- Analytical,
              detail-focused, and values accuracy.
            </Typography>
          )}
        </Box>
      ) : formData?.name?.toLowerCase().includes("financial style") ? (
        <Box sx={{ my: 5, whiteSpace: "pre-wrap" }}>
          <Typography variant="h5">Your Financial Style</Typography>
          {resultData() > 84 ? (
            <Typography sx={{ whiteSpace: "pre-wrap" }}>
              <strong>Achiever (A)</strong> <br />
              Achievers are driven, disciplined savers who actively pursue
              financial success. They set specific goals, invest wisely, and
              monitor progress closely. Example: Sarah consistently saves,
              invests, and seeks new opportunities to grow financially.
            </Typography>
          ) : resultData() > 64 ? (
            <Typography sx={{ whiteSpace: "pre-wrap" }}>
              <strong>Balancer (B)</strong> <br />
              Balancers value stability, save for the future, and enjoy present
              experiences. They budget wisely and maintain a healthy balance
              between saving and spending. Example: Mark budgets, saves, and
              enjoys occasional treats while staying financially responsible.
            </Typography>
          ) : resultData() > 44 ? (
            <Typography sx={{ whiteSpace: "pre-wrap" }}>
              <strong>Adventurers (V)</strong> <br />
              Adventurers embrace spontaneity, prioritize experiences, and may
              take financial risks for excitement. Example: Emily loves
              spontaneous adventures and investing in thrilling opportunities.
            </Typography>
          ) : (
            <Typography sx={{ whiteSpace: "pre-wrap" }}>
              <strong>Planner (P)</strong> <br />
              Planners are meticulous, preferring long-term security and careful
              financial planning. They budget, save, and analyze decisions.
            </Typography>
          )}
        </Box>
      ) : formData?.name?.toLowerCase().includes("work style") ? (
        <Box sx={{ my: 5 }}>
          <Typography variant="h5">Your Work Style</Typography>
          {resultData() > 25 ? (
            <Typography sx={{ whiteSpace: "pre-wrap" }}>
              <strong>🎨 Creativity (Imaginative work)</strong> <br />
              You thrive on exploring new ideas and thinking outside the box.
              You enjoy tasks that allow for creativity and innovation, whether
              it's through art, design, or problem-solving.
              <br />
              🌟 Example: You love brainstorming and coming up with unique
              ideas, exploring unconventional approaches, or working on projects
              that require original thinking
            </Typography>
          ) : resultData() > 20 ? (
            <Typography sx={{ whiteSpace: "pre-wrap" }}>
              <strong>🚀 Independence (Working alone)</strong> <br />
              You prefer working autonomously and are self-motivated to complete
              tasks independently. You enjoy the freedom to make decisions and
              work at your own pace without needing much input from others.
              <br />
              🌟 Example: You take full ownership of projects like conducting
              research or developing creative content, working with minimal
              supervision.
            </Typography>
          ) : resultData() > 15 ? (
            <Typography sx={{ whiteSpace: "pre-wrap" }}>
              <strong>🤝 Collaboration (Thrive on teamwork)</strong> <br />
              You excel in collaborative settings and enjoy working with others
              to achieve common goals. You value input from teammates and thrive
              on the synergy of collective effort.
              <br />
              🌟 Example: You actively participate in group discussions and team
              projects, sharing ideas and resources while supporting colleagues.
            </Typography>
          ) : (
            <Typography sx={{ whiteSpace: "pre-wrap" }}>
              <strong>⚡ Multitasking (Handling several tasks at once)</strong>{" "}
              <br />
              You enjoy juggling multiple tasks at once and can switch between
              them efficiently. You thrive in fast-paced environments and manage
              competing priorities well.
              <br /> 🌟 Example: You're comfortable responding to emails while
              on a conference call or working on multiple projects with
              overlapping deadlines.
            </Typography>
          )}
        </Box>
      ) : formData?.name?.toLowerCase().includes("thinking style") ? (
        <Box sx={{ my: 5 }}>
          <Typography variant="h5">Your Thinking Style</Typography>
          {resultData() > 50 ? (
            <Typography sx={{ whiteSpace: "pre-wrap" }}>
              <strong>Independent Thinker</strong> <br />
              ⭐ You are highly self-motivated and prefer working independently.
              You’re confident managing projects on your own and often produce
              your best work when working solo.
              <br /> ⭐ When given a task, you plan and execute it from start to
              finish without supervision, enjoying the freedom to explore
              solutions at your own pace, such as doing independent research for
              a project.
            </Typography>
          ) : resultData() > 35 ? (
            <Typography sx={{ whiteSpace: "pre-wrap" }}>
              <strong>Collaborative Thinker</strong> <br />
              ⭐ You excel in team settings where collaboration and
              communication are key. You believe multiple perspectives lead to
              better outcomes and enjoy solving problems as a team.
              <br />⭐ In group projects, you actively seek input from all
              members, facilitate discussions, and integrate everyone's ideas to
              develop a well-rounded solution.
            </Typography>
          ) : resultData() > 20 ? (
            <Typography sx={{ whiteSpace: "pre-wrap" }}>
              <strong>Creative Thinker</strong> <br />
              ⭐ You have a strong ability to think outside the box and approach
              problems with innovative solutions. Your thinking is guided by
              intuition and a desire to explore new possibilities.
              <br /> ⭐ When presented with a common issue, you brainstorm
              unique ideas that defy traditional approaches, such as creating an
              unconventional marketing campaign that captures attention in
              surprising ways.
            </Typography>
          ) : (
            <Typography sx={{ whiteSpace: "pre-wrap" }}>
              <strong>Analytical Thinker</strong> <br />
              ⭐ You are a methodical problem solver who likes to break down
              complex situations into manageable parts. You prioritize logic and
              detailed analysis in your decision-making process.
              <br /> ⭐ When faced with a project, you gather all relevant
              information, create a step-by-step plan, and methodically address
              each part before reaching a solution.
            </Typography>
          )}
        </Box>
      ) : formData?.name?.toLowerCase().includes("learning style") ? (
        <Box sx={{ my: 5 }}>
          <Typography variant="h5">Your Learning Style</Typography>
          {resultData() > 45 ? (
            <Typography sx={{ whiteSpace: "pre-wrap" }}>
              <strong>🖼️Visual</strong> <br />
              You learn best through seeing and visualizing. Visual inputs like
              diagrams and illustrations are most effective for you
            </Typography>
          ) : resultData() > 39 ? (
            <Typography sx={{ whiteSpace: "pre-wrap" }}>
              <strong>🎧Auditory</strong> <br />
              You prefer listening as your main method of learning, finding
              audio inputs more effective than visual ones.
            </Typography>
          ) : resultData() > 33 ? (
            <Typography sx={{ whiteSpace: "pre-wrap" }}>
              <strong>🛠️Physical</strong> <br />
              Hands-on activities are crucial to your learning process, as
              engaging directly with materials helps you learn.
            </Typography>
          ) : resultData() > 27 ? (
            <Typography sx={{ whiteSpace: "pre-wrap" }}>
              <strong>📚Verbal</strong> <br />
              You absorb and retain information effectively through reading,
              writing, and engaging in discussion
            </Typography>
          ) : resultData() > 21 ? (
            <Typography sx={{ whiteSpace: "pre-wrap" }}>
              <strong>📊Logical</strong> <br />
              Your approach to learning is methodical and structured, relying
              heavily on logic and clear methodologies.
            </Typography>
          ) : resultData() > 14 ? (
            <Typography sx={{ whiteSpace: "pre-wrap" }}>
              <strong>👥Social</strong> <br />
              You excel in learning environments that involve others, finding
              that collaboration enriches your understanding.
            </Typography>
          ) : (
            <Typography sx={{ whiteSpace: "pre-wrap" }}>
              <strong>🤫Solitary</strong> <br />
              You perform best when studying alone, as solitude allows you to
              focus and reflect deeply on the material.
            </Typography>
          )}
        </Box>
      ) : formData?.name?.toLowerCase().includes("work shift assessment") ? (
        <Box sx={{ my: 5 }}>
          <Typography variant="h5"> Your Work Shift Style:</Typography>
          {resultData() > 15 ? (
            <Typography sx={{ whiteSpace: "pre-wrap" }}>
              <strong>Morning Shift (6 AM - 2 PM)🌅</strong> <br />
              You’re a morning person who thrives on structure and enjoys starting your day early. A morning shift suits your productive hours and lifestyle.
            </Typography>
          ) : resultData() > 10 ? (
            <Typography sx={{ whiteSpace: "pre-wrap" }}>
              <strong>Day Shift (9 AM - 5 PM) ☀️</strong> <br />
              You function best with a steady routine and prefer a standard workday. A traditional 9-to-5 shift fits well with your work style and daily needs
            </Typography>
          ) : resultData() > 5 ? (
            <Typography sx={{ whiteSpace: "pre-wrap" }}>
              <strong>Evening Shift (2 PM - 10 PM)🌇 </strong> <br />
              You feel most productive later in the day and might have other commitments during the mornings. An evening shift would give you the flexibility and energy you need.
            </Typography>
          ) : (
            <Typography sx={{ whiteSpace: "pre-wrap" }}>
              <strong>Night Shift (10 PM - 6 AM)🌙</strong> <br />
              You’re a night owl who enjoys working when things are quieter. A night shift would allow you to make the most of your energy and flexibility preferences.
            </Typography>
          )}
        </Box>
      ) : formData?.name
        ?.toLowerCase()
        .includes("path to life and career readiness") ? (
        <Box sx={{ my: 5 }}>
          <Typography variant="h5">Your level of confidence</Typography>
          {resultData() > 24 ? (
            <Typography sx={{ whiteSpace: "pre-wrap" }}>
              <strong>🟢 High Confidence:</strong> <br />
              You're ready to tackle life and career challenges! Maintain your
              confidence by continuing to apply the skills you’ve learned and
              seeking new opportunities to grow.
            </Typography>
          ) : resultData() > 14 ? (
            <Typography sx={{ whiteSpace: "pre-wrap" }}>
              <strong>🟡 Moderate Confidence:</strong> <br />
              You're on the right path, but there’s still room for growth. Focus
              on enhancing your skills and building your confidence in the areas
              where you scored lower.
            </Typography>
          ) : (
            <Typography sx={{ whiteSpace: "pre-wrap" }}>
              <strong>🔴 Low Confidence:</strong> <br />
              This score indicates that you may need more support and practice
              in certain areas. Consider revisiting some lessons and focusing on
              developing skills where you feel less confident.
            </Typography>
          )}
        </Box>
      ) : formData?.name?.toLowerCase()?.includes("completing") ? (
        <Box sx={{ height: "220px" }}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              height: "100%",
              justifyContent: "center",
              fontSize: "28px",
              fontWeight: 700
            }}
          >
            {formData?.name}
          </div>
        </Box>
      ) : (
        <Box>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: 5,
              justifyContent: "flex-end",
            }}
          >
            <h2
              style={{
                fontWeight: "normal",
                fontSize: "18px",
                color: "#242424",
              }}
            >
              Total Points:
            </h2>
            <h4
              style={{
                backgroundColor: theme.palette.primary.main,
                padding: "10px 15px",
                color: "#ffffff",
                borderRadius: 10,
              }}
            >{`${resultData()}`}</h4>
          </div>
          <div
            style={{
              width: "100%",
              backgroundColor: "#F9FAFC",
              padding: "0px",
            }}
          >
            <Typography
              sx={{ whiteSpace: "pre-wrap" }}
              variant="body1"
              color="initial"
            >
              {extractTextFromHTML(formData?.successDescription || "")}
            </Typography>
          </div>
        </Box>
      )}
    </>
  );
};
