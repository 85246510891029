import React, { useEffect } from 'react'
import { Container } from '@mui/material'
import SkillsBar from '../components/lessons/skills/SkillsBar'
import SkillSectionsTopBar from '../components/common/SkillSectionsTopBar'
import isAuthenticated from '../components/auth/auth'
import { useNavigate } from 'react-router-dom'
import SkillSectionButtons from '../components/common/SkillSectionButtons'
import { useTheme, useMediaQuery } from '@mui/material'
export default function SkillSectionLayout(props) {
    const { children, page } = props
    const theme = useTheme()
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"))
    const navigate = useNavigate()
    useEffect(() => {
        if (!isAuthenticated(localStorage.getItem("token"))) {
            navigate("/login")
        }
    }, [children, page])
    return (
        <Container maxWidth="xl" sx={{ px: { xs: 0 }, py: { md: "22px", xs: "0px" } }}>
            <SkillsBar page={page} />
            <SkillSectionsTopBar />
            {children}
            {
                !isMobile &&
                <SkillSectionButtons {...props} />
            }
        </Container>
    )
}
