
import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme) => ({

  skillAssignmentButton: {
    position: "fixed",
    top: "50%",
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '20px 5px',
    transform: "translateY(-50%)",
    zIndex: theme.zIndex.drawer + 2,
    background: `linear-gradient(90deg, ${theme.palette.primary.dark} 0%, ${theme.palette.primary.main} 100%)`,
    borderTopLeftRadius: "5px",
    borderBottomLeftRadius: "5px",
    boxShadow: theme.shadows[3],
    cursor: "pointer",
    transition: 'all 0.15s ease-in-out',
  },
  fixedBottomButtons: {
    position: "fixed",
    bottom: 20,
    [theme.breakpoints.down("sm")]: {
      bottom: 5,
    },
    left: "50%",
    transform: 'translateX(-50%)',
    zIndex: 1,
    display: "flex",
    gap: 15,
  },
  fixedBottomButtonsSmallScreen: {
    position: "fixed",
    bottom: 5,
    left: "55%",
    transform: 'translateX(-50%)',
    zIndex: 1,
    display: "flex",
    gap: 15,
  },
  flexColCenterVertical: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  flexColCenter: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  skillSectionContainer: {
    marginLeft: 0, // Default for xs breakpoint
    paddingLeft: "10px",
    paddingRight: "10px",
    [theme.breakpoints.up("sm")]: {
      marginLeft: "80px", // Apply 120px marginLeft on sm and up
      paddingLeft: "30px",
      paddingRight: "30px",
    },
    [theme.breakpoints.up("lg")]: {
      marginLeft: "120px", // Apply 120px marginLeft on lg and up
      paddingLeft: "43px",
      paddingRight: "43px",
    },
    [theme.breakpoints.down("sm")]: {
      paddingLeft: "0px",
      paddingRight: "0px",
    },

  },
  stickbotButtons: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-around",
    alignItems: "center",
  },
  btnUpdateStyle: {
    position: "fixed",
    left: 15,
    bottom: 50,
    padding: "7px 20px",
    backgroundColor: theme.palette.common.white,
    border: "none",
    borderRadius: "10px",
    color: "#616ded",
    width: "fit-content",
    boxShadow: "0 0 10px 0 rgba(0, 0, 0, 0.4)",
  },
  btnAddStyle: {
    position: "fixed",
    left: 20,
    bottom: 50,
    padding: "7px 30px",
    backgroundColor: theme.palette.common.white,
    border: "none",
    borderRadius: "10px",
    color: "#616ded",
    width: "fit-content",
    boxShadow: "0 0 10px 0 rgba(0, 0, 0, 0.4)",
  },
  nameStyling: {
    fontSize: { xs: "1.5rem", md: "2rem" },
    color: theme.palette.primary.main,
    fontWeight: 700,
    marginBottom: ".2rem",
  },

  descStyling: {
    fontSize: { xs: ".8rem", md: "1rem" },
    color: theme.palette.common.text,
  },

  quilStyle: {
    fontSize: "16px",
    marginBottom: "20px",
    marginTop: "20px",
    color: theme.palette.common.text,
  },

  ccVideoButtonSix: {
    position: "absolute",
    top: "10px",
    right: "10px",
    width: "23px",
    height: "21px",
    borderRadius: ".3rem",
    color: theme.palette.common.white,
  },

  addDropdown: {
    marginTop: 10,
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: "20px",
  },

  inputStyle: {
    position: "absolute",
    left: "1px",
    backgroundColor: theme.palette.common.white,
    width: "95%",
    opacity: "0%",
    textAlign: "center",
  },

  errorStyle: {
    margin: 0,
    color: theme.palette.error.main,
    textAlign: "left",
    paddingLeft: "20px",
    fontSize: 12,
  },

  audioText: {
    // minWidth: 200,
    // maxWidth: 500,
    textAlign: "left",
    padding: "20px",
    margin: 0,
    overflow: "auto",
    whiteSpace: 'pre-wrap'
  },

  resizeableBox: {
    borderRadius: "25px",
    border: `1px solid ${theme.palette.common.disabled}`,
    overflowX: "hidden",
    overflowY: "auto",
    padding: "19px 28px",
    [theme.breakpoints.down("sm")]: {
      padding: "0px 19px",
    },
  },
  truncatedText: {
    display: '-webkit-box',
    WebkitBoxOrient: 'vertical',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  outerStack: {
    marginTop: "32px",
    width: "100%",
    display: 'flex',
    justifyContent: 'space-between',
    backgroundColor: theme.palette.common.dialougeBg,
    padding: '8px',
    borderRadius: '30px',
    boxShadow: 'inset 0px 0px 10px 4px rgba(0, 0, 0, 0.1)', // Inner shadow
  },
  button: {
    width: "50%",
    padding: '10px 34px',
    borderRadius: '30px',
    fontWeight: 'bold',
    color: theme.palette.common.white,
  },
  activeButton: {
    backgroundColor: theme.palette.primary.main,
  },
  inactiveButton: {
    backgroundColor: theme.palette.common.disabled,
    color: theme.palette.common.white,
  }
}));