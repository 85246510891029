
import React, { forwardRef, useState } from 'react'
import { Box, Button, IconButton, Link, Typography, Container, Grid } from '@mui/material'
import { useTranslation } from 'react-i18next'
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import Slide from '@mui/material/Slide';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';

import Tutorial from "./page-tutorials";
import Popup from './Popup';

const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function Footer({ pl, pr }) {
    const { t } = useTranslation()
    const [openHelp, setOpenHelp] = useState(false)
    const [showDisclaimer, setShowDisclaimer] = useState(true)

    if (!showDisclaimer) return <></>
    return (
        <Box sx={{ height: { sm: "100px", xs: "200px" } }} >
            {/* <Popup /> */}
            <Box sx={{
                width: '100%',
                py: { lg: 1, xs: .5 },
                px: { sm: 2, xs: .5 },
                position: "fixed",
                bottom: 0,
                left: 0,
                right: 0,
                zIndex: 1,
                pl: { sm: pl && pl },
                pr: { sm: pr && pr },
                backgroundColor: (theme) => theme.palette.primary.main,
            }}
            >
                <Container maxWidth="xl" sx={{ p: 0 }}>

                    <Box sx={footerStyle}>

                        <Tutorial />
                        <Typography variant="body1" color="white" sx={{ textAlign: "justify", fontSize: { sm: "12px", xs: "10px" }, marginLeft: "30px" }}>
                            {/* {t("disclaimerText")} */}
                        </Typography>
                        <Button
                            onClick={() => setOpenHelp(true)}
                            variant='outlined'
                            sx={{
                                textTransform: "capitalize",
                                fontSize: { md: "16px", xs: "9px" },
                                p: { xs: ".5px 5px", md: "2px 5px" },
                                minWidth: "fit-content",
                                height: "fit-content",
                                // backgroundColor:"#115c82",
                                color: "white",
                                border: "1px #fff solid"
                            }}
                        >
                            {t("help")}
                        </Button>

                    </Box>
                </Container>
            </Box>
            <Dialog
                open={openHelp}
                TransitionComponent={Transition}
                keepMounted
                onClose={() => setOpenHelp(false)}
                fullWidth
                maxWidth="md"
                PaperProps={{
                    sx: {
                        borderRadius: "15px",
                        m: { sm: "32px", xs: "3px" },
                        width: { sm: "calc(100% - 64px)", xs: "100%" },
                    }
                }}
            >
                <DialogContent sx={{ position: 'relative', p: { sm: 9, xs: 2 }, display: "flex", gap: 3.5, flexDirection: 'column', overflowX: 'hidden' }}>
                    <IconButton
                        onClick={() => setOpenHelp(false)}
                        sx={{ position: 'absolute', top: 3, right: 6, color: "#000" }}>
                        <CloseRoundedIcon />
                    </IconButton>
                    <Typography sx={{ mt: 1 }} variant="h4" color="initial" fontWeight={600}>
                        {t("needHelp")}
                    </Typography>
                    <Typography variant="body1" color="initial">
                        For technical support with Stickball course content, please email: 
                        <Link href="mailto:developers@stickball.biz" > developers@stickball.biz </Link>
                        
                    </Typography>
                    <Typography variant="body1" color="initial"> Please provide an explanation of the issue you are having as well as the following information below as part of your support request:</Typography>
                    <List sx={{ ml: 2 }} >
                        {
                            [
                                "Operating System: Windows",
                                "Browser: Mozilla/5.0 (Windows NT 10.0; Win64;x64) AppleWebKit/537.36 (KHTML, Like Gecko) Chrome/125.0.0.0 Safari/537.36",
                                "Device: Win32"
                            ].map(item => {
                                return (
                                    <ListItem disablePadding sx={{ alignItems: 'flex-start' }} key={item}>
                                        <ListItemIcon sx={{ minWidth: 25, mt: 1 }}>
                                            <FiberManualRecordIcon sx={{ width: 15, height: 15, color: "black" }} />
                                        </ListItemIcon>
                                        <ListItemText primary={item} />
                                    </ListItem>
                                )
                            })
                        }
                    </List>
                    <img style={{ marginLeft: 20 }} src="assets/svg/logo.gif" width={130} height={40} alt="" />
                </DialogContent>
            </Dialog>
        </Box>
    )
}


const footerStyle = {
    gap: '10px',
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    width: '100%',
    p: 0
}